/* eslint-disable max-len */
export interface IManager {
	photo: string;
	name: string;
	education: string;
	experience: string;
	description: string;
	languages?: string;
}

const middle = '6–10 Years';
const noPhoto = '/images/pvm/no_photo_sm.jpg';

export const INTRO = 'Did you know that our sales team has a 95%+ rating based on OVC customer satisfaction surveys? Get to know them! Learn more about our Personal Vacation Managers\' travel industry certifications and experience and education below.';
export const managerList: Array<IManager> = [
	{
		photo: '/images/pvm/abigail_sm.jpg',
		name: 'Abigail M',
		education: 'Johnson and Wales University, B.S.',
		experience: '1–5 Years',
		description: 'Certified Specialist in Azamara, Celebrity Cruises, Enrichment Journeys, Holland America Line, Oceania Cruises, Princess Cruises, Royal Caribbean Cruise Line, Norwegian Cruise Line',
	},
	{
		photo: '/images/pvm/aidy_sm.jpg',
		name: 'Aidy A',
		education: 'Florida International University, B.S.',
		experience: '15+ Years',
		description: 'Certified Specialist in Azamara, Celebrity, Norwegian Cruise Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean, Viking River Cruises',
	},
	{
		photo: '/images/pvm/alissa_sm.jpg',
		name: 'Alissa M',
		education: 'Attended Mars Hill College and Broward Community College',
		experience: '20+ Years',
		description: 'Certified Specialist in Azamara, Celebrity Cruises, Disney, Globus, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/annette_sm_2.jpg',
		name: 'Annette P',
		education: 'Florida International University, B.S.',
		experience: middle,
		description: 'Certified Specialist in Celebrity Cruises, Disney, Enrichment Journeys, Globus, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/emily_sm.jpg',
		name: 'Emily G',
		education: 'University of Florida, B.S.',
		experience: middle,
		description: 'Certified Specialist in Carnival Cruise Line, Celebrity Cruises, Cunard Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises, Certified Accessible Travel Advocate®',
	},
	{
		photo: noPhoto,
		name: 'Edwina M',
		education: 'Boston University, M. Ed., B.S. and Suffolk University, M.B.A.',
		experience: middle,
		description: 'Certified Specialist in Celebrity Cruises, Crystal Cruises, Cunard Line, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises, Uniworld; Certified Accessible Travel Advocate®',
	},
	{
		photo: noPhoto,
		name: 'Gaby D',
		education: 'University of Central Florida, B.S.',
		experience: '1–5 Years',
		description: 'Certified Specialist in Azamara, Carnival Cruise Line, Celebrity Cruises, Disney, Holland America Line, Norwegian Cruise Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises',
	},
	{
		photo: '/images/pvm/Ireland.jpg',
		name: 'Ireland D',
		education: '',
		experience: '30+ Years',
		description: 'Certified specialist Celebrity, Royal Caribbean, Princess, Holland, Azamara, Oceania, Viking, Regent Trafalgar Tours, Tauck tours, Universal Studios, Disney, Norwegian Cruise',
	},
	{
		photo: '/images/pvm/jasmine.jpg',
		name: 'Jasmine U',
		education: 'Coastal Carolina University, B.A.',
		experience: '1-2 Years',
		description: 'Certified Specialist in Azamara, Celebrity Cruises, Holland America Line, Norwegian Cruise Line, Princess Cruises, Royal Caribbean Cruise Line, Viking Cruises.',
	},
	{
		photo: '/images/pvm/Karina.jpg',
		name: 'Karina M',
		education: 'Florida International University B.S.',
		experience: '2-4 Years',
		description: 'Certified Specialist in Viking Cruises, Celebrity Cruises, Holland America Line, Oceania Cruises, Princess Cruises, Norwegian Cruise Line, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Virgin Voyages, Insight Vacations.',
	},
	{
		photo: '/images/pvm/Kassandra.jpg',
		name: 'Kassandra J',
		education: 'Education: Health Services Administration, B.A',
		experience: '7 Years',
		description: 'Certified Specialist in Celebrity Cruises, Holland America Line, Princess Cruises, Oceania, Norwegian Cruises, Globus, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises and Virgin Voyages',
	},
	{
		photo: '/images/pvm/kathleen_sm_2.jpg',
		name: 'Kathleen B',
		education: 'Michigan State University, M.S., B.S.',
		experience: middle,
		description: 'Certified Specialist in Azamara, Carnival Cruise Line, Celebrity Cruises, Disney, Globus, Holland America Line, Norwegian Cruise Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/kristina_sm_2.jpg',
		name: 'Kristina P',
		education: 'University of Florida, B.A.',
		experience: middle,
		description: 'Certified Specialist in Azamara, Carnival Cruise Line, Celebrity Cruises, Cunard Line, Disney, Globus, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/Liah.jpg',
		name: 'Liah M',
		education: 'Associates Business Management, Bachelor’s Psychology',
		experience: '13 Years',
		description: 'Certified Specialist in  RCCL, Cel, NCL, PRC, Globus, Oceania, Regent, Viking,',
	},
	{
		photo: noPhoto,
		name: 'Marie W',
		education: 'Broward College, A.A.',
		experience: '11–15 Years',
		description: 'Certified Specialist in Celebrity Cruises, Globus, Marriott Hotels, Norwegian Cruise Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Sheraton Hotels, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/michael_sm.jpg',
		name: 'Michael V',
		education: 'University of Central Florida, B.S.',
		experience: '11–15 Years',
		description: 'Certified Specialist in AM Resorts, Azamara, Carnival Cruise Line, Celebrity Cruises, Disney, Holland America Line, Globus, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Trafalgar Tours, Viking Cruises, Uniworld; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/sabrina_sm.jpg',
		name: 'Sabrina S',
		education: 'Euro-Business-College Germany, B.A. and University of Sunderland UK, B.A.',
		experience: middle,
		languages: 'German, Spanish',
		description: 'Certified Specialist in Azamara, Celebrity Cruises, Cunard Line, Disney, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
	{
		photo: '/images/pvm/sophia_sm.jpg',
		name: 'Sophia R',
		education: 'University of Florida, B.S.',
		experience: middle,
		description: 'Certified Specialist in Azamara, Celebrity Cruises, Cunard Line, Disney, Globus, Holland America Line, Oceania Cruises, Princess Cruises, Regent Seven Seas Cruises, Royal Caribbean Cruise Line, Viking Cruises; Certified Accessible Travel Advocate®',
	},
].sort((a: IManager, b: IManager) => (a.name > b.name ? 1 : -1));
