import * as React from 'react';

import { TIcon } from '@app/objects/Utility';

interface SocialIconsProps {
	list: Array<SocialIcon>;
}

export interface SocialIcon {
	Icon: TIcon;
	link: string;
}

export const SocialIcons: React.FC<SocialIconsProps> = (props: SocialIconsProps) => (
	<>
		{props.list.map((icon: SocialIcon) => (
			<a href={icon.link} className="footer__social-icon-item" key={icon.link} target="_blank" rel="noreferrer">
				<icon.Icon />
			</a>
		))}
	</>
);
