export const baseUrl = 'https://localhost:44313';
export const ImageUrl = 'https://www.onlinevacationcenter.com/images/';
export const CardImages = 'https://www.onlinevacationcenter.com/images/specials_images/maps/';
export const DefaultCardImages = '/images/specials_images/';
export const DestImages = 'https://www.onlinevacationcenter.com/images/vipExclusives/destinations/';
export const SellerImagesUrl = 'https://www.onlinevacationcenter.com/images/vipexclusives/';
export const PvmLink = '/managers';
export const SearchValuInclusiveUrl = '/search?type=0&order.direction=1&order.tag=Price&page=1';
export const FeaturedURL = '/search?cruiseLine=';
export const DestinationURL = '/search?destination=';
export const SpecialDealURL = '/special_deal.cfm?specialsid=';
