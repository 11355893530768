/* eslint-disable max-len */
import { CardItem } from '@app/pages/MainPage/CruiseLine/CruiseLine';

import {
	celebrity,
	oceania,
	cabins,
	norwegian,
	azamara,
	royalCaribbean,
	shipView,
	people,
} from '@image/cruiseLine/index';

export const cardsLeft: Array<CardItem> = [
	{
		image: norwegian,
		background: '#E0F7FF',
		link: 'https://res.onlinevacationcenter.com/swift/cruise?siid=228902&lang=1&minduration=3&maxduration=999&destinationtype=All&cruiseline=6&sortColumn=price&sortOrder=asc',
	},
	{
		image: people,
	},
	{
		image: cabins,
	},
	{
		image: royalCaribbean,
		background: 'white',
		link: 'https://res.onlinevacationcenter.com/swift/cruise?siid=228902&lang=1&minduration=3&maxduration=999&destinationtype=All&cruiseline=8&sortColumn=price&sortOrder=asc',
	},
];

export const cardsRight: Array<CardItem> = [
	{
		image: celebrity,
		background: 'white',
		link: 'https://res.onlinevacationcenter.com/swift/cruise?siid=228902&lang=1&MinDuration=3&MaxDuration=999&destinationtype=All&cruiseline=2&sortColumn=price&sortOrder=asc',
	},
	{
		image: azamara,
		background: '#E0F7FF',
		link: 'https://res.onlinevacationcenter.com/swift/cruise?siid=228902&lang=1&minduration=3&maxduration=999&destinationtype=All&cruiseline=1043&sortColumn=price&sortOrder=asc',
	},
	{
		image: shipView,
		className: 'big-ship-item',
		children: [
			{
				image: oceania,
				background: '#207EB8',
				className: 'holland-item',
				link: 'https://res.onlinevacationcenter.com/swift/cruise?siid=228902&lang=1&minduration=3&maxduration=999&destinationtype=All&cruiseline=14&sortColumn=price&sortOrder=asc',
			},
		],
	},
];
