import * as React from 'react';
import { useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Field, FieldProps } from 'formik';

import { TextField } from '@app/components/TextField/TextField';
import { FormTemplate, IMessage, SuccessMessage } from '@app/pages/Forms/FormTemplate';
import { simpleStringSchema, phoneValidator } from '@app/services/validationSchemas';
import { TextAreaField } from '@app/components/TextField/TextAreaField';
import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { simpleChoiceOption } from '@app/components/Selects/LocalSelectOptions';
import { LocalSelectOptions } from '@app/objects/LocalSelectOptions';
import { Nullable } from '@app/objects/Utility';
import { ManagersSelect } from '@app/components/Selects/SmartSelects/ManagersSelect';
import { RootStore } from '@app/store';
import { LinkType, link } from '@app/pages/StaticPages/utils';
import { request } from '@app/services/api';
import { NotificationType } from '@app/components/Notification/Notification';
import { formatPhone } from '@app/services/phone';

const subtitle = `Questions or comments? You can use the form below to contact us with any cruise or travel questions you may have.
We're here to assist you!`;

interface ContactFormValues {
	firstName: string;
	lastName: string;
	email: string;
	zip?: string;
	phone: string;
	secondaryPhone: string;
	note: string;
	receiveNewsletters: boolean;
	manager: Nullable<number>;

	agreeSubmittingTerms: boolean;
}

const initialValues: ContactFormValues = {
	firstName: '',
	lastName: '',
	email: '',
	zip: '',
	phone: '',
	secondaryPhone: '',
	note: '',
	receiveNewsletters: true,
	manager: -2,

	agreeSubmittingTerms: false,
};

const validationSchema = Yup.object().shape({
	firstName: simpleStringSchema,
	lastName: simpleStringSchema,
	zip: simpleStringSchema,
	phone: phoneValidator,
	email: Yup.string().email('Invalid email format').required('This field is required!'),
	note: simpleStringSchema,
	agreeSubmittingTerms: Yup.bool().required('This field is required!'),
});

const ExtraContent = () => {
	const phone = useSelector((state: RootStore) => state.header.item?.phone);

	return (
		<div className="col-12 col-md-8 form__subtitle form__extra-contact">
			{phone && <p>Our Phone: {link(phone, LinkType.Phone)}</p>}
			<p className="form__extra-contact__text">Our E-mail: {link('customerservice@onlinevacationcenter.com', LinkType.Mail)}</p>
		</div>
	);
};

const InnerForm = (): JSX.Element => (
	<div className="col-12">
		<div className="form-group_large form-group_outlined">
			<h3 className="form-group__title">Personal Information</h3>
			<div className="layout-container">
				<Field name="firstName">
					{({ field, form }: FieldProps<string, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}

								title="First Name*"

								text={form.touched.firstName ? form.errors.firstName : undefined}
								error={Boolean(form.errors.firstName && form.touched.firstName)}
							/>
						</div>
					)}
				</Field>
				<Field name="lastName">
					{({ field, form }: FieldProps<string, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}

								title="Last Name*"

								text={form.touched.lastName ? form.errors.lastName : undefined}
								error={Boolean(form.errors.lastName && form.touched.lastName)}
							/>
						</div>
					)}
				</Field>
				<Field name="email">
					{({ field, form }: FieldProps<string, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}

								title="E-mail*"
								inputProps={{ type: 'email' }}

								text={form.touched.email ? form.errors.email : undefined}
								error={Boolean(form.errors.email && form.touched.email)}
							/>
						</div>
					)}
				</Field>
				<Field name="zip">
					{({ field, form }: FieldProps<string, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}

								title="Zip/Postal Code*"

								text={form.touched.zip ? form.errors.zip : undefined}
								error={Boolean(form.errors.zip && form.touched.zip)}
							/>
						</div>
					)}
				</Field>
				<Field name="phone">
					{({ field, form }: FieldProps<number, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}
								inputProps={{ type: 'tel' }}

								title="Primary Phone*"

								text={form.touched.phone ? form.errors.phone : undefined}
								error={Boolean(form.errors.phone && form.touched.phone)}
							/>
						</div>
					)}
				</Field>
				<Field name="secondaryPhone">
					{({ field, form }: FieldProps<number, ContactFormValues>) => (
						<div className="col-12 col-sm-6 col-xl-4 form-group">
							<TextField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}
								inputProps={{ type: 'tel' }}

								title="Secondary Phone"

								text={form.touched.secondaryPhone ? form.errors.secondaryPhone : undefined}
								error={Boolean(form.errors.secondaryPhone && form.touched.secondaryPhone)}
							/>
						</div>
					)}
				</Field>
			</div>
		</div>
		<div className="form-group_large form-group_outlined">
			<h3 className="form-group__title">Preferences</h3>
			<div className="layout-container">
				<Field name="receiveNewsletters">
					{({ field, form }: FieldProps) => (
						<div className="col-12 col-sm-6 form-group">
							<Select
								placeholder="Would you like to Join our Newsletters?"
								value={field.value ? Number(field.value) : field.value}
								onChange={(values: number) => {
									form.setFieldValue(field.name, values);
								}}
								allowClear={false}
							>
								{
									simpleChoiceOption.map((option: LocalSelectOptions) => (
										<Option key={option.value} value={option.value}>
											{option.text}
										</Option>
									))
								}
							</Select>
						</div>
					)}
				</Field>
				<Field name="manager">
					{({ field, form }: FieldProps<number, ContactFormValues>) => (
						<div className="col-12 col-sm-6 form-group">
							<ManagersSelect
								value={field.value}
								onChange={(value?: Nullable<number>) => form.setFieldValue(field.name, value, false)}
							/>
						</div>
					)}
				</Field>
			</div>
		</div>
		<div className="layout-container">
			<Field name="note">
				{({ field, form }: FieldProps<number, ContactFormValues>) => (
					<div className="col-12 form-group">
						<TextAreaField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Additional Comments"

							text={form.touched.note ? form.errors.note : undefined}
							error={Boolean(form.errors.note && form.touched.note)}
						/>
					</div>
				)}
			</Field>
		</div>
	</div>
);

const SuccessExtraMessage: React.FC = () => {
	const header = useSelector((store: RootStore) => store.header.item);
	const phone = header?.phone;

	return (
		<p className="form__subtitle" style={{ marginTop: 25, width: '80%' }}>
			We appreciate your interest in Online Vacation Center.
			A Personal Vacation Manager will contact you as soon as possible.
			If you have any questions and would like immediate help, please call us at
			{
				phone ? (
					<>
						<br />{link(formatPhone(phone), LinkType.Phone)}
					</>
				) : null
			}
		</p>
	);
};

export const Contact = () => {
	const [message, setMessage] = React.useState<IMessage | undefined>(undefined);

	return (
		<FormTemplate
			title="Contact Us"
			subtitle={subtitle}
			innerForm={<InnerForm />}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ContactFormValues, { setSubmitting, resetForm }) => {
				const params = new URLSearchParams(window.location.search);

				const bookingId = params.get('bkId');
				const specialsId = params.get('specials');

				setMessage(undefined);
				request('api/bridge/v1/contact-form', {
					method: 'POST',
					data: {
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						zip: values.zip,
						phone: values.phone,
						pvmId: values.manager,
						secondaryPhone: values.secondaryPhone,
						AdditionalNotes: values.note,
						SignUp: Boolean(values.receiveNewsletters),
						agreeSubmittingTerms: Boolean(values.agreeSubmittingTerms),
						specialsId,
						bookingId,
						fuseAction: 'contact',
					},
				})
					.then((response) => {
						console.log(response);
						resetForm();
						setMessage({
							type: NotificationType.Success,
							content: (
								<SuccessMessage
									title="Thank you!"
									subtitle="Your Request has been Received"
									extra={<SuccessExtraMessage />}
								/>
							),
						});
					})
					.catch((error: Error) => {
						console.warn('Failed to saved contact details: ', error.message);
						setMessage({ type: NotificationType.Error, content: 'Failed to saved contact details' });
					})
					.finally(() => setSubmitting(false));
			}}
			message={message}
		/>
	);
};
