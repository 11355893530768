import React, { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { httpModules } from '@app/helpers/http.module'; // Adjust path as per your setup

interface ISpacialHeadline {
	specialsID: number;
	specialsHeadline: string;
}

interface SpacialHeadlinesProps {
	specialsId: string | null;
}

export const SpacialHeadlines: React.FC<SpacialHeadlinesProps> = ({ specialsId }) => {
	const [spacialHeadline, setspacialHeadline] = useState<Array<ISpacialHeadline>>([]);

	useEffect(() => {
		if (specialsId) {
			const apiUrl = `api/bridge/v1/FriendForward-form/SpecialsHeadline?specialsid=${specialsId}`;
			httpModules
				.get<Array<ISpacialHeadline>>(apiUrl)
				.then((response: AxiosResponse<Array<ISpacialHeadline>>) => {
					setspacialHeadline(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	const subtitle = `Send this special to your friend: ${spacialHeadline.length > 0 ? spacialHeadline[0].specialsHeadline : ''}`;

	return (
		<div style={{ textAlign: 'center', marginTop: '50px' }}>
			<h1>Forward To A Friend</h1>
			<p style={{ textAlign: 'center', marginTop: '25px' }}>{subtitle}</p>
		</div>
	);
};
